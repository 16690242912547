
$(function(){
	var attentionPrecedntCurrentPage = 1;
	var attentionPrecedentMaxPage = 3;
	var attentionPrecedentSliderSwitch = $('.jsSliderAttentionPrecedent');
	attentionPrecedentSliderSwitch.children('.jsSliderSwitchBack').on('click', function() {
		attentionPrecedntCurrentPage--;
		sliderAnimation($('.jsAttentionPrecedentSlider'), attentionPrecedentSliderSwitch, attentionPrecedntCurrentPage, attentionPrecedentMaxPage);
	});
  attentionPrecedentSliderSwitch.children('.jsSliderSwitchNext').on('click', function() {
		attentionPrecedntCurrentPage++;
    sliderAnimation($('.jsAttentionPrecedentSlider'), attentionPrecedentSliderSwitch, attentionPrecedntCurrentPage, attentionPrecedentMaxPage);
  });
	function sliderAnimation(sliderList, sliderSwitch, currentPage, maxPage){
		sliderList.animate({
				left : -(currentPage - 1)*1010 + 'px'
				}, {
				duration : 500, //速度の調整
		});
		sliderList.attr('data-current-page', currentPage);
		sliderSwitchDisplayCheck(sliderSwitch, currentPage, maxPage);
	}
	// 最初・最終ページの矢印切り替え
	function sliderSwitchDisplayCheck(sliderSwitch, currentPage, maxPage){
		sliderSwitch.children('.jsSliderSwitchBack').removeClass('d-n');
		sliderSwitch.children('.jsSliderSwitchNext').removeClass('d-n');
		if(currentPage == 1){
				sliderSwitch.children('.jsSliderSwitchBack').addClass('d-n');
		}
		if(currentPage == maxPage){
				sliderSwitch.children('.jsSliderSwitchNext').addClass('d-n');
		}
	}
});